import React from 'react';

import MarketNavigation from './MarketNavigation/MarketNavigation';
import MarketHome from './MarketHome/MarketHome';
import MarketAbout from './MarketAbout/MarketAbout';
import MarketProjects from './MarketProjects/MarketProjects';
import Form from '../DefaultPage/DefaultPageComponents/Form/Form';
import Footer from '../DefaultPage/DefaultPageComponents/Footer/Footer';

const MarketPage = () => {

    return (
        <>

            <MarketNavigation />
            <MarketHome />
            <MarketAbout />
            <MarketProjects />
            <Form />
            <Footer />

        </>
    );
};

export default MarketPage;