import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import { Navigation, Pagination, Autoplay } from 'swiper/modules'
import 'swiper/swiper-bundle.css';

import { MdOutlineBedroomChild } from "react-icons/md";
import { MdOutlineBathroom } from "react-icons/md";
import { BsTextarea } from "react-icons/bs";
import { CiLocationOn } from "react-icons/ci";
import hnsLogo from './images (6).png';

import './market_projects.css';

const MarketProjects = () => {

    const sectionScrollHandler = (sectionId) => {
        const section = document.getElementById(sectionId);

        if (section) {
            window.scrollTo({
                top: section.offsetTop,
                behavior: "smooth"
            });
        }
    };

    const settings = {
        spaceBetween: 70,
        loop: true,
        autoplay: {
            delay: 4000,
            disableOnInteraction: true,
        },
        navigation: true,
        // pagination: {
        //     clickable: true,
        // },
        breakpoints: {
            450: {
                slidesPerView: 1,
                slidesPerGroup: 1
            },
            1200: {
                slidesPerView: 3,
                slidesPerGroup: 1
            }
        },
    };

    return (
        <>
            <section id="market_projects_section">

                <h1 id="market_projects_hdng">Our Featured Projects</h1>

                <Swiper id="carousel"
                    modules={[Navigation, Pagination, Autoplay]}
                    {...settings}
                >
                    {/* emaar parkedge */}
                    <SwiperSlide id="carosel_slide">
                        <div className='project_card'>
                            <div className='project_card_img_div'
                                id="emaar_parkedge"></div>
                            <div className='project_card_content_div'>
                                <div id="project_logo">
                                    <img style={{ width: 'auto', height: '100%' }}
                                        src="https://cdn.pk.emaar.com/wp-content/themes/emaar/inc/assets/images/emaar-logo.svg" alt="" />
                                </div>
                                <h5 id='project_card_hdng'>
                                    Park Edge
                                </h5>
                                <p id='project_card_text'>
                                    Luxury Ocean front project
                                </p>
                            </div>
                            <div id="project_detail_div">
                                <div id="project_price_box">
                                    <h3 id="booking_">BOOKING FROM</h3>
                                    <p id="project_price">54,000,000</p>
                                </div>
                                <hr style={{ width: '1px', height: '90%', backgroundColor: 'gray', margin: '0' }} />
                                <div id="project_amenities_box">
                                    <span className="amenities_">
                                        <BsTextarea style={{ fontSize: '20px', color: '#083554' }} />
                                        500 Sq.Ft.
                                    </span>
                                    <span className="amenities_">
                                        <MdOutlineBedroomChild style={{ fontSize: '20px', color: '#083554' }} />
                                        2
                                    </span>
                                    <span className="amenities_">
                                        <MdOutlineBathroom style={{ fontSize: '20px', color: '#083554' }} />
                                        2
                                    </span>
                                </div>
                            </div>
                            <div id="project_location">
                                <CiLocationOn />
                                DHA Phase 8 Karachi
                            </div>
                            <Link id="project_btn" to='/projects/emaar-parkedge' target='_blank'>
                                View Details
                            </Link>
                        </div>
                    </SwiperSlide>

                    {/* hns residence */}
                    <SwiperSlide id="carosel_slide">
                        <div className='project_card'>
                            <div className='project_card_img_div'
                                id="hns_residence"></div>
                            <div className='project_card_content_div'>
                                <div id="project_logo">
                                    <img style={{ width: 'auto', height: '100%' }}
                                        src={hnsLogo} alt="" />
                                </div>
                                <h5 id='project_card_hdng'>
                                    H&S Residence
                                </h5>
                                <p id='project_card_text'>
                                    Luxury Sea-Facing Apartments In HMR Waterfront
                                </p>
                            </div>
                            <div id="project_detail_div">
                                <div id="project_price_box">
                                    <h3 id="booking_">BOOKING FROM</h3>
                                    <p id="project_price">34,000,000</p>
                                </div>
                                <hr style={{ width: '1px', height: '90%', backgroundColor: 'gray', margin: '0' }} />
                                <div id="project_amenities_box">
                                    <span className="amenities_">
                                        <BsTextarea style={{ fontSize: '20px', color: '#083554' }} />
                                        500 Sq.Ft.
                                    </span>
                                    <span className="amenities_">
                                        <MdOutlineBedroomChild style={{ fontSize: '20px', color: '#083554' }} />
                                        2
                                    </span>
                                    <span className="amenities_">
                                        <MdOutlineBathroom style={{ fontSize: '20px', color: '#083554' }} />
                                        2
                                    </span>
                                </div>
                            </div>
                            <div id="project_location">
                                <CiLocationOn />
                                DHA Phase 8 Karachi
                            </div>
                            <Link id="project_btn" to='/projects/h&s-residence' target='_blank'>
                                View Details
                            </Link>
                        </div>
                    </SwiperSlide>

                    {/* gold crest */}
                    <SwiperSlide id="carosel_slide">
                        <div className='project_card'>
                            <div className='project_card_img_div'
                                id="gold_crest"></div>
                            <div className='project_card_content_div'>
                                <div id="project_logo">
                                    <img style={{ width: 'auto', height: '100%' }}
                                        src='https://goldcrestbaysands.com/wp-content/uploads/2023/07/reallogo-removebg-preview-1.png' alt="" />
                                </div>
                                <h5 id='project_card_hdng'>
                                    Gold Crest Bay Sands
                                </h5>
                                <p id='project_card_text'>
                                    A Coastal Paradise With 3 Sky-High Towers
                                </p>
                            </div>
                            <div id="project_detail_div">
                                <div id="project_price_box">
                                    <h3 id="booking_">BOOKING FROM</h3>
                                    <p id="project_price">29,000,000</p>
                                </div>
                                <hr style={{ width: '1px', height: '90%', backgroundColor: 'gray', margin: '0' }} />
                                <div id="project_amenities_box">
                                    <span className="amenities_">
                                        <BsTextarea style={{ fontSize: '20px', color: '#083554' }} />
                                        500 Sq.Ft.
                                    </span>
                                    <span className="amenities_">
                                        <MdOutlineBedroomChild style={{ fontSize: '20px', color: '#083554' }} />
                                        2
                                    </span>
                                    <span className="amenities_">
                                        <MdOutlineBathroom style={{ fontSize: '20px', color: '#083554' }} />
                                        2
                                    </span>
                                </div>
                            </div>
                            <div id="project_location">
                                <CiLocationOn />
                                DHA Phase 8 Karachi
                            </div>
                            <Link id="project_btn" to='/projects/gold-crest' target='_blank'>
                                View Details
                            </Link>
                        </div>
                    </SwiperSlide>
                </Swiper>

            </section>


        </>
    );
};

export default MarketProjects;

